import React from 'react';

interface Point {
  icon: string;
  title: string;
  description: string;
  gradient?: string;
}

const struggles: Point[] = [
  {
    icon: '📞',
    gradient: 'from-red-500 via-rose-500 to-pink-500',
    title: 'Missed Calls = Missed Revenue',
    description: "Your phone rings after hours, and no one is there to answer—potential customers go to competitors."
  },
  {
    icon: '🔍',
    gradient: 'from-rose-500 via-red-500 to-orange-500',
    title: 'Leads Fall Through the Cracks',
    description: 'Without an efficient follow-up system, many leads never turn into customers.'
  },
  {
    icon: '💰',
    gradient: 'from-orange-500 via-amber-500 to-yellow-500',
    title: 'Expensive Marketing Agencies',
    description: 'Traditional agencies charge a fortune for minimal results, leaving you frustrated and out of budget.'
  },
  {
    icon: '🌐',
    gradient: 'from-yellow-500 via-amber-500 to-orange-500',
    title: 'Low Online Visibility',
    description: "You are struggling to rank on Google, making it hard for customers to find you."
  },
  {
    icon: '⏰',
    gradient: 'from-red-500 via-rose-500 to-pink-500',
    title: 'Time-Consuming Admin Tasks',
    description: 'Chasing leads, scheduling appointments, and managing reviews eats up your time, leaving little room to focus on growth.'
  }
];

const solutions: Point[] = [
  {
    icon: '🎯',
    gradient: 'from-indigo-500 via-purple-500 to-pink-500',
    title: '24/7 AI Lead Capture',
    description: 'Never miss a lead again. Our AI system captures and responds to inquiries instantly, any time of day or night.'
  },
  {
    icon: '⚡',
    gradient: 'from-rose-500 via-pink-500 to-purple-500',
    title: 'Smart Follow-up System',
    description: 'Automated sequences nurture leads with personalized messages until they are ready to convert.'
  },
  {
    icon: '🏆',
    gradient: 'from-blue-500 via-cyan-500 to-teal-500',
    title: 'Done-For-You Marketing',
    description: 'Get a complete marketing system with SEO website, Google Screened ads, and automated review collection.'
  },
  {
    icon: '📈',
    gradient: 'from-amber-400 via-orange-400 to-rose-400',
    title: 'Predictable Growth',
    description: 'Watch your business grow with a proven system that consistently generates and converts leads.'
  },
  {
    icon: '⭐',
    gradient: 'from-blue-500 via-indigo-500 to-purple-500',
    title: 'Reputation Management',
    description: 'Automatically collect and manage reviews, building a strong online presence that attracts more customers.'
  }
];

const PainPoints: React.FC = () => {
  return (
    <section className="py-24 bg-[#090D1F] relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(#394BFF_1px,transparent_1px)] [background-size:16px_16px] opacity-[0.15]"/>
        <div className="absolute inset-0 bg-gradient-to-b from-[#090D1F] via-transparent to-[#090D1F]"/>
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">
            Transform Your Business Growth
          </h2>
          <p className="text-xl text-gray-300">
            Stop struggling with outdated marketing methods. Experience the power of AI automation.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          {/* Struggles Section */}
          <div>
            <h3 className="text-2xl font-semibold mb-8 text-center">Common Struggles</h3>
            <div className="space-y-6">
              {struggles.map((point, index) => (
                <div key={index} className="flex items-start gap-4 bg-white/5 p-6 rounded-xl backdrop-blur-sm">
                  <div className={`w-10 h-10 rounded-lg bg-gradient-to-r ${point.gradient} flex items-center justify-center`}>
                    <span className="text-xl">{point.icon}</span>
                  </div>
                  <div>
                    <h4 className="font-semibold mb-2">{point.title}</h4>
                    <p className="text-gray-300">{point.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Solutions Section */}
          <div>
            <h3 className="text-2xl font-semibold mb-8 text-center">Our Solutions</h3>
            <div className="space-y-6">
              {solutions.map((point, index) => (
                <div key={index} className="flex items-start gap-4 bg-white/5 p-6 rounded-xl backdrop-blur-sm">
                  <div className={`w-10 h-10 rounded-lg bg-gradient-to-r ${point.gradient} flex items-center justify-center`}>
                    <span className="text-xl">{point.icon}</span>
                  </div>
                  <div>
                    <h4 className="font-semibold mb-2">{point.title}</h4>
                    <p className="text-gray-300">{point.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PainPoints;
