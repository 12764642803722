import React from 'react';
import { Check } from 'lucide-react';

const TransformationFeatures = [
  {
    title: 'Custom AI Strategy Development',
    description: 'In-depth discovery to align AI workflows with your business goals',
    features: [
      'Industry-specific configurations',
      'Business goal alignment',
      'Customized workflow planning'
    ]
  },
  {
    title: 'Workflow Implementation',
    description: 'Building and customizing AI-driven workflows for your needs',
    features: [
      'Lead generation automation',
      'Follow-up systems',
      'Review management',
      'CRM integration'
    ]
  },
  {
    title: 'Google Business Optimization',
    description: 'Complete setup and optimization for local visibility',
    features: [
      'Profile verification',
      'Local SEO setup',
      'Review management system'
    ]
  },
  {
    title: 'Custom AI Website',
    description: 'Industry-specific website with conversion tools',
    features: [
      'Booking forms',
      'Lead capture',
      'SEO optimization',
      'Mobile-first design'
    ]
  },
  {
    title: 'Instagram AI Growth System',
    description: 'Automated engagement & lead generation on Instagram',
    features: [
      '24/7 AI-powered DM response system',
      'Smart hashtag optimization',
      'Automated booking from comments',
      'Engagement analytics & tracking'
    ]
  },
  {
    title: '90-Day Success Program',
    description: 'Hands-on guidance to ensure maximum results',
    features: [
      'Weekly check-ins',
      'Performance monitoring',
      'System optimization',
      'Team training'
    ]
  }
];

const monthlyPlans = [
  {
    name: 'Starter',
    price: '497',
    description: 'Essential AI-powered automation for modern businesses',
    features: [
      'Custom Lead Funnels',
      'AI-Powered Communication',
      'Review Booster AI',
      'Local Presence Enhancer',
      'Basic Social Media Scheduler',
      'Progress Dashboard',
      'Dedicated Support'
    ]
  },
  {
    name: 'Growth',
    price: '797',
    description: 'Advanced AI solutions for scaling businesses',
    features: [
      'Everything in Starter',
      'Appointment AI',
      'Smart Lead Nurturing',
      'Enhanced Review Management',
      'Advanced Social Media AI',
      'Customizable Workflows'
    ],
    popular: true
  },
  {
    name: 'Pro',
    price: '1,297',
    description: 'Full-scale AI automation for market leaders',
    features: [
      'Everything in Growth',
      'Unlimited AI Employee Usage',
      'Dynamic Workflow Automation',
      'White-Glove Support',
      '24/7 AI Concierge',
      'Dedicated Success Manager'
    ]
  }
];

const PricingSection: React.FC<{ onGetStarted: () => void }> = ({ onGetStarted }) => {
  const features = [
    {
      title: "Custom AI Strategy Development",
      description: "In-depth discovery to align AI workflows with your business goals",
      items: [
        "Industry-specific configurations",
        "Business goal alignment",
        "Customized workflow planning"
      ],
      icon: ""
    },
    {
      title: "Workflow Implementation",
      description: "Building and customizing AI-driven workflows for your needs",
      items: [
        "Lead generation automation",
        "Follow-up systems",
        "Review management",
        "CRM integration"
      ],
      icon: ""
    },
    {
      title: "Google Business Optimization",
      description: "Complete setup and optimization for local visibility",
      items: [
        "Profile verification",
        "Local SEO setup",
        "Review management system"
      ],
      icon: ""
    },
    {
      title: "Custom AI Website",
      description: "Industry-specific website with conversion tools",
      items: [
        "Booking forms",
        "Lead capture",
        "SEO optimization",
        "Mobile-first design"
      ],
      icon: ""
    },
    {
      title: "Instagram AI Growth System",
      description: "Automated engagement & lead generation on Instagram",
      items: [
        "24/7 AI-powered DM response system",
        "Smart hashtag optimization",
        "Automated booking from comments",
        "Engagement analytics & tracking"
      ],
      icon: ""
    },
    {
      title: "90-Day Success Program",
      description: "Hands-on guidance to ensure maximum results",
      items: [
        "Weekly check-ins",
        "Performance monitoring",
        "System optimization",
        "Team training"
      ],
      icon: ""
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-[#0A0B2C] to-[#0A0B2C]/80">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-16 space-y-4">
          <h2 className="text-4xl font-bold bg-gradient-to-r from-blue-400 via-indigo-400 to-purple-400 bg-clip-text text-transparent">
            Complete Business Automation Solution
          </h2>
          <p className="text-xl text-blue-200/80 max-w-2xl mx-auto">
            Everything you need to transform your business with AI automation
          </p>
        </div>

        {/* Pricing Card */}
        <div className="max-w-lg mx-auto mb-16">
          <div className="relative group">
            {/* Animated background gradients */}
            <div className="absolute -inset-[2px] bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 rounded-2xl opacity-75 blur-lg group-hover:opacity-100 transition-opacity duration-500"></div>
            
            {/* Main content */}
            <div className="relative bg-[#0A0B2C]/90 backdrop-blur-xl rounded-2xl p-8 border border-white/10">
              {/* Price Tag */}
              <div className="text-center mb-8">
                <span className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-blue-500/10 via-indigo-500/10 to-purple-500/10 border border-blue-500/20 backdrop-blur-sm mb-4">
                  <span className="w-2 h-2 rounded-full bg-blue-400 animate-pulse"></span>
                  <span className="bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-transparent font-medium">
                    Limited Time Offer
                  </span>
                </span>
                <div className="flex items-center justify-center gap-4">
                  <span className="text-2xl text-blue-200/60 line-through">$9,997</span>
                  <span className="text-5xl font-bold bg-gradient-to-r from-blue-400 via-indigo-400 to-purple-400 bg-clip-text text-transparent">
                    $4,997
                  </span>
                </div>
                <p className="text-blue-200/80 mt-2">Save 50% - Limited Time Only</p>
              </div>

              {/* Quick Benefits */}
              <div className="space-y-3 mb-8">
                {[
            'Complete System Setup',
            '90 Days of Guided Support',
            'Custom AI Strategy',
            'Lifetime Access to Platform'
        ].map(function (benefit, index) { return (<div key={index} className="flex items-center gap-3">
                    <Check className="w-5 h-5 text-blue-400 shrink-0"/>
                    <span className="text-blue-100">{benefit}</span>
                  </div>); })}
              </div>

              {/* CTA Button */}
              <button onClick={onGetStarted} className="w-full py-4 bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 rounded-xl text-white font-semibold hover:from-blue-600 hover:via-indigo-600 hover:to-purple-600 transition-all duration-300 shadow-lg hover:shadow-xl hover:shadow-blue-500/20">
                Start Your Transformation
              </button>
              <p className="text-center text-blue-200/60 text-sm mt-4">
                Limited spots available. Reserve yours now.
              </p>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map(function (feature, index) { return (<div key={index} className="relative group bg-[#0A0B2C]/50 rounded-2xl p-6 border border-white/10 hover:border-white/20 transition-all duration-300">
              {/* Gradient Background Effect */}
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 via-indigo-500/5 to-purple-500/5 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"/>
              
              {/* Content */}
              <div className="relative space-y-4">
                {/* Icon and Title */}
                <div className="flex items-start gap-4">
                  <span className="text-2xl">{feature.icon}</span>
                  <div>
                    <h3 className="text-xl font-semibold bg-gradient-to-r from-white to-blue-100 bg-clip-text text-transparent">
                      {feature.title}
                    </h3>
                    <p className="text-sm text-blue-200/70 mt-1">
                      {feature.description}
                    </p>
                  </div>
                </div>

                {/* Feature List */}
                <ul className="space-y-3">
                  {feature.items.map(function (item, itemIndex) { return (<li key={itemIndex} className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-blue-400 shrink-0 mt-0.5"/>
                      <span className="text-blue-100/90">{item}</span>
                    </li>); })}
                </ul>
              </div>
            </div>); })}
        </div>

        {/* CTA Section */}
        <div className="mt-16 text-center">
          <button onClick={onGetStarted} className="px-8 py-4 bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 rounded-full text-white font-semibold hover:from-blue-600 hover:via-indigo-600 hover:to-purple-600 transition-all duration-300 shadow-lg hover:shadow-xl hover:shadow-blue-500/20">
            Start Your 90-Day Transformation
          </button>
        </div>

        {/* Monthly Plans */}
        <div className="mt-20">
          <div className="text-center mb-16">
            <div className="inline-block">
              <span className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-violet-500/10 via-purple-500/10 to-fuchsia-500/10 border border-violet-500/20 backdrop-blur-sm">
                <span className="w-2 h-2 rounded-full bg-gradient-to-r from-violet-500 to-purple-500"></span>
                <span className="bg-gradient-to-r from-violet-400 via-purple-400 to-fuchsia-400 bg-clip-text text-transparent font-medium">
                  Ongoing Support
                </span>
              </span>
            </div>
            <h2 className="text-4xl font-bold mt-4 mb-6">
              Post-Program{' '}
              <span className="bg-gradient-to-r from-violet-400 via-purple-400 to-fuchsia-400 text-transparent bg-clip-text">
                Support Plans
              </span>
            </h2>
            <p className="text-blue-100 max-w-2xl mx-auto">
              Choose the perfect support plan to maintain and optimize your AI marketing system
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {monthlyPlans.map(function (plan, index) { return (<div key={index} className={"p-8 rounded-xl ".concat(plan.popular
                ? 'bg-gradient-to-br from-secondary/20 to-blue-500/20 border-secondary/20 relative'
                : 'bg-[#0A0B2C]/80 border-white/5', " border backdrop-blur-xl hover:transform hover:scale-[1.02] transition-all duration-300")}>
                {plan.popular && (<div className="absolute -top-4 left-1/2 -translate-x-1/2">
                    <div className="relative">
                      <div className="absolute inset-0 bg-gradient-to-r from-secondary via-blue-500 to-secondary blur-lg opacity-30"></div>
                      <span className="relative inline-flex items-center gap-2 px-6 py-2 rounded-full bg-gradient-to-r from-secondary via-blue-500 to-secondary border border-secondary/20 shadow-lg shadow-secondary/20">
                        <span className="flex items-center gap-1.5">
                          <span className="w-1.5 h-1.5 rounded-full bg-white"></span>
                          <span className="text-white font-medium text-sm">
                            Most Popular
                          </span>
                          <span className="w-1.5 h-1.5 rounded-full bg-white"></span>
                        </span>
                      </span>
                    </div>
                  </div>)}

                <div className="text-center mb-8">
                  <h3 className="text-2xl font-bold text-white mb-2">{plan.name}</h3>
                  <div className="flex items-center justify-center gap-1 mb-2">
                    <span className="text-4xl font-bold text-white">${plan.price}</span>
                    <span className="text-blue-100">/month</span>
                  </div>
                  <p className="text-blue-100">{plan.description}</p>
                </div>

                <div className="space-y-4 mb-8">
                  {plan.features.map(function (feature, idx) { return (<div key={idx} className="flex items-center gap-3">
                      <Check className="w-5 h-5 text-emerald-400"/>
                      <span className="text-blue-100">{feature}</span>
                    </div>); })}
                </div>

                <button onClick={onGetStarted} className={"w-full py-3 px-6 rounded-lg font-medium transition-all duration-300 ".concat(plan.popular
                ? 'bg-gradient-to-r from-secondary via-blue-500 to-secondary bg-[length:200%_100%] hover:bg-[length:100%_100%] text-white'
                : 'bg-white/10 hover:bg-white/20 text-white')}>
                  Get Started
                </button>
              </div>); })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
