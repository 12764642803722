import React from 'react';
import FormField from '../FormField';

interface AdditionalDetailsProps {
  formData: {
    challenge?: string;
  };
  onChange: (name: string, value: string) => void;
}

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({ formData, onChange }) => {
  const challengeOptions = [
    { value: 'not-enough-leads', label: 'Not getting enough leads' },
    { value: 'poor-quality', label: 'Poor lead quality' },
    { value: 'high-cost', label: 'High cost per lead' },
    { value: 'low-conversion', label: 'Low conversion rate' },
    { value: 'inconsistent', label: 'Inconsistent results' },
    { value: 'other', label: 'Other' }
  ];

  return (
    <div>
      <FormField
        label="What is your biggest challenge with lead generation?"
        name="challenge"
        type="select"
        value={formData.challenge}
        onChange={onChange}
        options={challengeOptions}
        required
      />
    </div>
  );
};

export default AdditionalDetails;
