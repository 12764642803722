"use strict";
import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
    {
        question: 'What industries does this program support?',
        answer: 'Our program is specifically designed for home services businesses, real estate professionals, and local service providers. This includes HVAC, landscaping, junk removal, property management, realtors, and similar businesses.'
    },
    {
        question: 'Is the setup process difficult?',
        answer: 'Not at all! We provide step-by-step guidance and our team handles the technical setup. You will receive comprehensive training on managing the system, but the initial setup is done for you.'
    },
    {
        question: 'Can I customize the workflows?',
        answer: 'Yes, absolutely! All automation workflows are customized to your specific business needs and can be adjusted as your business evolves. We will help you set up the perfect workflow for your industry.'
    },
    {
        question: 'How long until I see results?',
        answer: 'Most clients start seeing results within the first 30 days. By the end of the 90-day program, you will have a fully operational marketing system generating consistent leads.'
    },
    {
        question: 'What kind of support do you provide?',
        answer: 'You will receive dedicated support throughout the 90-day program, including weekly strategy calls, technical support, and access to our expert community. After the program, ongoing support is available based on your chosen plan.'
    },
    {
        question: 'What happens after the 90 days?',
        answer: 'By the end of 90 days, you will have a fully operational, AI-powered marketing system that you can manage independently. You will have access to our platform and community for ongoing support and updates.'
    }
];

const FAQ = () => {
    const [openIndex, setOpenIndex] = React.useState(0);
    return (<section className="py-24 bg-[#000437]" id="faq">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">Frequently Asked Questions</h2>
          <p className="text-blue-100 max-w-2xl mx-auto">
            Everything you need to know about the Market Bots transformation program
          </p>
        </div>

        <div className="max-w-3xl mx-auto space-y-4">
          {faqs.map((faq, index) => (<div key={index} className="border border-blue-500/10 rounded-lg overflow-hidden hover:border-blue-500/20 transition-colors">
              <button className="w-full p-6 flex items-center justify-between text-left bg-[#0A0B2C]/80 hover:bg-[#0A0B2C] transition-colors" onClick={() => setOpenIndex(openIndex === index ? -1 : index)}>
                <span className="font-semibold text-white">{faq.question}</span>
                {openIndex === index ? (<ChevronUp className="w-5 h-5 text-[#394BFF]"/>) : (<ChevronDown className="w-5 h-5 text-[#394BFF]"/>)}
              </button>
              
              {openIndex === index && (<div className="px-6 pb-6 bg-[#0A0B2C]/80">
                  <p className="text-blue-100">{faq.answer}</p>
                </div>)}
            </div>))}
        </div>
      </div>
    </section>);
};

export default FAQ;
