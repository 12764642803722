import React from 'react';

const ThankYou: React.FC = () => {
  return (
    <section className="min-h-screen bg-[#000437] py-24">
      <div className="container max-w-3xl mx-auto px-4">
        <div className="bg-[#0A0B2C]/80 backdrop-blur-xl rounded-2xl p-8 border border-white/5 text-center">
          <div className="w-16 h-16 mx-auto mb-6 rounded-full bg-secondary/20 flex items-center justify-center">
            <svg className="w-8 h-8 text-secondary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </div>

          <h1 className="text-3xl font-bold text-white mb-4">Application Submitted!</h1>
          <p className="text-blue-100 mb-8">
            Thank you for your interest. Our team will review your application and get back to you within 24 hours.
          </p>

          <div className="max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-white mb-4">What Happens Next?</h2>
            <div className="space-y-4">
              {[
                'Our team will review your application within 24 hours',
                'We\'ll prepare a custom growth strategy for your business',
                'Schedule a strategy call to discuss implementation'
              ].map((step, index) => (
                <div key={index} className="flex items-center gap-3 text-left">
                  <div className="w-6 h-6 rounded-full bg-secondary/20 flex-shrink-0 flex items-center justify-center">
                    <svg className="w-4 h-4 text-secondary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                  </div>
                  <span className="text-blue-100">{step}</span>
                </div>
              ))}
            </div>
          </div>

          <button onClick={() => window.location.href = '/'} className="mt-8 px-6 py-3 bg-secondary hover:bg-secondary/90 text-white rounded-lg transition-colors">
            Return to Homepage
          </button>
        </div>
      </div>
    </section>
  );
};

export default ThankYou;
