import React from 'react';
import FormField from '../FormField';

interface BusinessInfoProps {
  formData: {
    businessName: string;
    industry: string;
    website: string;
  };
  onChange: (name: string, value: string) => void;
  onNext: () => void;
}

const BusinessInfo: React.FC<BusinessInfoProps> = ({ formData, onChange, onNext }) => {
  const industries = [
    { value: 'home-services', label: 'Home Services' },
    { value: 'real-estate', label: 'Real Estate' },
    { value: 'hvac', label: 'HVAC' },
    { value: 'construction', label: 'Construction' },
    { value: 'legal', label: 'Legal Services' },
    { value: 'medical', label: 'Medical & Healthcare' },
    { value: 'automotive', label: 'Automotive' },
    { value: 'other', label: 'Other' }
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.businessName && formData.industry && formData.website) {
      onNext();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <h2 className="text-2xl font-bold text-white mb-8">Tell us about your business</h2>
      
      <FormField
        label="What's the name of your business we'll be helping to scale?"
        name="businessName"
        value={formData.businessName}
        onChange={onChange}
        placeholder="Enter your business name"
        required
      />
      
      <FormField
        label="What industry are you in?"
        name="industry"
        type="select"
        value={formData.industry}
        onChange={onChange}
        options={industries}
        required
      />

      <FormField
        label="What's your website URL?"
        name="website"
        type="url"
        value={formData.website}
        onChange={onChange}
        placeholder="https://example.com"
        required
      />

      <div className="flex justify-end">
        <button
          type="submit"
          className="px-6 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:opacity-90 transition-opacity"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default BusinessInfo;
