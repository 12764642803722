import { motion, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import { useEffect, useState, useCallback } from 'react';

interface Particle {
  id: number;
  x: number;
  y: number;
  size: number;
  color: string;
}

const LoadingScreen = () => {
  const [particles, setParticles] = useState<Particle[]>([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const progressValue = useMotionValue(0);
  const progress = useTransform(progressValue, [0, 100], [0, 1]);
  const controls = useAnimation();

  // Generate particles with different sizes and colors
  useEffect(() => {
    const colors = ['#60A5FA', '#3B82F6', '#2563EB', '#1D4ED8'];
    const particleCount = 40;
    const newParticles = Array.from({ length: particleCount }, (_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 1.5 + 0.5, // Varied sizes
      color: colors[Math.floor(Math.random() * colors.length)],
    }));
    setParticles(newParticles);

    // Animate progress
    progressValue.set(0);
    controls.start({
      width: '100%',
      transition: { duration: 2, ease: 'easeInOut' }
    });
    progressValue.set(100);
  }, []);

  // Handle mouse movement
  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setMousePosition({
      x: ((e.clientX - rect.left) / rect.width) * 100,
      y: ((e.clientY - rect.top) / rect.height) * 100,
    });
  }, []);

  return (
    <motion.div
      className="fixed inset-0 bg-[#090D1F] z-50 flex items-center justify-center overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onMouseMove={handleMouseMove}
    >
      <div className="absolute inset-0 opacity-20">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 via-transparent to-purple-500/20" />
      </div>

      <div className="relative w-96 h-96">
        {/* Particles */}
        {particles.map((particle) => (
          <motion.div
            key={particle.id}
            className="absolute rounded-full"
            style={{
              left: `${particle.x}%`,
              top: `${particle.y}%`,
              width: `${particle.size * 8}px`,
              height: `${particle.size * 8}px`,
              background: `radial-gradient(circle at center, ${particle.color}, transparent)`,
              boxShadow: `0 0 ${particle.size * 5}px ${particle.color}`,
            }}
            initial={{ scale: 0, opacity: 0 }}
            animate={{
              scale: [0, 1, 0.8],
              opacity: [0, 0.8, 0.4],
              x: mousePosition.x ? (mousePosition.x - particle.x) * 0.1 : 0,
              y: mousePosition.y ? (mousePosition.y - particle.y) * 0.1 : 0,
            }}
            transition={{
              duration: 2 + Math.random(),
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
              delay: Math.random() * 0.5,
            }}
          />
        ))}
        
        {/* Central Content */}
        <motion.div
          className="absolute inset-0 flex flex-col items-center justify-center"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {/* Logo Container */}
          <div className="relative w-24 h-24 mb-6">
            <motion.div
              className="absolute inset-0 rounded-full"
              style={{
                background: 'radial-gradient(circle at center, #3B82F6, transparent)',
                boxShadow: '0 0 20px #3B82F6',
              }}
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.5, 0.8, 0.5],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.div
              className="absolute inset-0 border-2 border-blue-500 rounded-full"
              animate={{
                rotate: 360,
                scale: [1, 1.1, 1],
              }}
              transition={{
                rotate: {
                  duration: 8,
                  repeat: Infinity,
                  ease: "linear",
                },
                scale: {
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }
              }}
            />
          </div>

          {/* Text and Progress */}
          <div className="text-center z-10">
            <motion.h1 
              className="text-3xl font-bold text-white mb-4"
              animate={{
                opacity: [0.5, 1, 0.5],
                textShadow: [
                  '0 0 8px rgba(59, 130, 246, 0.5)',
                  '0 0 16px rgba(59, 130, 246, 0.8)',
                  '0 0 8px rgba(59, 130, 246, 0.5)',
                ],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            >
              Market Bots
            </motion.h1>

            {/* Progress Bar */}
            <div className="w-48 h-1 bg-gray-800 rounded-full overflow-hidden mx-auto">
              <motion.div
                className="h-full bg-gradient-to-r from-blue-600 via-blue-400 to-blue-600"
                style={{ scaleX: progress, originX: 0 }}
                animate={controls}
              />
            </div>

            {/* Loading Dots */}
            <div className="flex items-center justify-center space-x-2 mt-4">
              {[0, 1, 2].map((i) => (
                <motion.div
                  key={i}
                  className="w-2 h-2 bg-blue-500 rounded-full"
                  animate={{
                    scale: [1, 1.5, 1],
                    opacity: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: i * 0.2,
                  }}
                />
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default LoadingScreen;
