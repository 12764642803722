import React, { useState } from 'react';
import { ArrowRight, ChevronDown, CheckCircle } from 'lucide-react';

interface HeroProps {
  onGetStarted: () => void;
}

interface Stat {
  value: string;
  label: string;
}

interface Industry {
  title: string;
  description: string;
}

const Hero: React.FC<HeroProps> = ({ onGetStarted }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const stats: Stat[] = [
    { value: '10+', label: 'Years Experience' },
    { value: '300%', label: 'Average ROI' },
    { value: '100%', label: 'Success Rate' }
  ];

  const industries: Industry[] = [
    {
      title: 'Home Services',
      description: 'Stop losing $1000s in missed after-hours calls - our AI handles all HVAC, plumbing, and service inquiries 24/7'
    },
    {
      title: 'Real Estate',
      description: 'Never miss a hot lead - 24/7 response for property managers, realtors, and mortgage brokers'
    },
    {
      title: 'Local Providers',
      description: 'Streamline patient scheduling for dental, healthcare, and medical practices 24/7'
    }
  ];

  return (
    <section className="min-h-screen pt-20 bg-primary relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,rgba(57,75,255,0.3),transparent_50%)]"/>
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,rgba(57,75,255,0.2),transparent_50%)]"/>
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#000437_0%,rgba(0,4,55,0.8)_100%)]"/>
      </div>
      
      <div className="container mx-auto px-4 pt-12 relative">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Left Column */}
          <div className="space-y-8">
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500/10 via-purple-500/10 to-pink-500/10 text-white border border-indigo-500/20 backdrop-blur-sm hover:border-indigo-500/30 transition-colors">
              <span className="w-5 h-5 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full flex items-center justify-center text-white text-xs">📅</span>
              <span className="bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 bg-clip-text text-transparent font-medium">
                90-Day Transformation Program
              </span>
            </div>

            <div className="space-y-6">
              <h2 className="text-2xl md:text-3xl font-bold">
                The Ultimate Marketing System for Local Businesses
              </h2>

              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold">
                <span className="text-white">
                  Never Miss a Lead. Build 5-Star Reviews.{' '}
                </span>
                <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                  Dominate Your Local Market.
                </span>
              </h1>

              <ul className="space-y-4">
                <li className="flex items-center gap-3 group">
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-teal-500 blur-lg opacity-0 group-hover:opacity-50 transition-opacity duration-300"/>
                    <CheckCircle className="text-emerald-400 w-5 h-5 flex-shrink-0 relative transition-colors duration-300 group-hover:text-emerald-300"/>
                  </div>
                  <span className="text-blue-100 group-hover:text-white transition-colors duration-300">
                    Capture Leads 24/7: Never miss a call or follow-up.
                  </span>
                </li>
                <li className="flex items-center gap-3 group">
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-teal-500 blur-lg opacity-0 group-hover:opacity-50 transition-opacity duration-300"/>
                    <CheckCircle className="text-emerald-400 w-5 h-5 flex-shrink-0 relative transition-colors duration-300 group-hover:text-emerald-300"/>
                  </div>
                  <span className="text-blue-100 group-hover:text-white transition-colors duration-300">
                    Rank Higher on Google: Attract more local customers.
                  </span>
                </li>
                <li className="flex items-center gap-3 group">
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-teal-500 blur-lg opacity-0 group-hover:opacity-50 transition-opacity duration-300"/>
                    <CheckCircle className="text-emerald-400 w-5 h-5 flex-shrink-0 relative transition-colors duration-300 group-hover:text-emerald-300"/>
                  </div>
                  <span className="text-blue-100 group-hover:text-white transition-colors duration-300">
                    Boost 5-Star Reviews: Make reputation management easy with AI.
                  </span>
                </li>
                <li className="flex items-center gap-3 group">
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-teal-500 blur-lg opacity-0 group-hover:opacity-50 transition-opacity duration-300"/>
                    <CheckCircle className="text-emerald-400 w-5 h-5 flex-shrink-0 relative transition-colors duration-300 group-hover:text-emerald-300"/>
                  </div>
                  <span className="text-blue-100 group-hover:text-white transition-colors duration-300">
                    Automate Follow-Ups: Nurture leads and book appointments effortlessly.
                  </span>
                </li>
              </ul>

              <button
                onClick={onGetStarted}
                className="inline-flex items-center gap-2 px-6 py-3 rounded-lg bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold transition-all duration-300 transform hover:scale-105 hover:shadow-[0_0_40px_8px_rgba(59,130,246,0.5)]"
              >
                Get Your AI Marketing System
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>

            {/* <p className="text-xl text-blue-100 leading-relaxed">
              Our{' '}
              <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent font-semibold">
                AI system
              </span>{' '}
              captures leads 24/7, ranks you #1 on Google, and books appointments automatically - while you sleep.
            </p>

            <ul className="space-y-4">
              <li className="flex items-center gap-3 group">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-teal-500 blur-lg opacity-0 group-hover:opacity-50 transition-opacity duration-300"/>
                  <CheckCircle className="text-emerald-400 w-5 h-5 flex-shrink-0 relative transition-colors duration-300 group-hover:text-emerald-300"/>
                </div>
                <span className="text-blue-100 group-hover:text-white transition-colors duration-300">
                  Never miss another{' '}
                  <span className="bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent font-semibold">
                    after-hours lead
                  </span>
                </span>
              </li>
              <li className="flex items-center gap-3 group">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-teal-500 blur-lg opacity-0 group-hover:opacity-50 transition-opacity duration-300"/>
                  <CheckCircle className="text-emerald-400 w-5 h-5 flex-shrink-0 relative transition-colors duration-300 group-hover:text-emerald-300"/>
                </div>
                <span className="text-blue-100 group-hover:text-white transition-colors duration-300">
                  Rank{' '}
                  <span className="bg-gradient-to-r from-emerald-400 to-teal-400 bg-clip-text text-transparent font-semibold">
                    #1 in Google Maps
                  </span>{' '}
                  and Local Search
                </span>
              </li>
              <li className="flex items-center gap-3 group">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-teal-500 blur-lg opacity-0 group-hover:opacity-50 transition-opacity duration-300"/>
                  <CheckCircle className="text-emerald-400 w-5 h-5 flex-shrink-0 relative transition-colors duration-300 group-hover:text-emerald-300"/>
                </div>
                <span className="text-blue-100 group-hover:text-white transition-colors duration-300">
                  Book more appointments{' '}
                  <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent font-semibold">
                    automatically
                  </span>
                </span>
              </li>
            </ul> */}

            {/* <button onClick={onGetStarted} className="group relative inline-flex items-center gap-2 px-8 py-4 bg-gradient-to-r from-secondary via-blue-500 to-secondary bg-[length:200%_100%] text-white rounded-lg font-medium transition-all duration-500 hover:bg-[length:100%_100%] hover:scale-105 hover:shadow-[0_0_40px_8px_rgba(57,75,255,0.3)] overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-r from-secondary via-blue-500 to-secondary opacity-0 group-hover:opacity-20 blur-xl transition-opacity"/>
              <span className="relative">Get Your AI Marketing System</span>
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform relative"/>
            </button> */}
          </div>

          {/* Right Column - System Overview */}
          <div className="relative group">
            {/* Animated background gradients */}
            <div className="absolute -inset-[2px] bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 rounded-2xl opacity-75 blur-lg group-hover:opacity-100 transition-opacity duration-500"></div>
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 via-indigo-500/10 to-purple-500/10 rounded-2xl backdrop-blur-xl"></div>
            
            {/* Main content */}
            <div className="relative bg-[#0A0B2C]/90 backdrop-blur-xl rounded-2xl p-8 space-y-8 border border-white/10">
              {/* Header */}
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <h3 className="text-2xl font-bold bg-gradient-to-r from-white to-blue-100 bg-clip-text text-transparent">
                    System Overview
                  </h3>
                  <p className="text-sm text-blue-200/80">Real-time AI Performance</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded-full bg-green-400 animate-pulse"></div>
                  <span className="px-4 py-1.5 rounded-full bg-gradient-to-r from-green-500/10 to-emerald-500/10 border border-green-500/20 backdrop-blur-sm">
                    <span className="bg-gradient-to-r from-green-400 to-emerald-400 bg-clip-text text-transparent font-medium">
                      AI Powered
                    </span>
                  </span>
                </div>
              </div>

              {/* Stats Section */}
              <div className="grid grid-cols-3 gap-6">
                {stats.map((stat, index) => (
                  <div key={index} className="relative group/stat p-4 rounded-xl bg-gradient-to-b from-white/5 to-white/0 border border-white/10 hover:border-white/20 transition-all duration-300">
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 via-indigo-500/5 to-purple-500/0 rounded-xl opacity-0 group-hover/stat:opacity-100 transition-opacity duration-500"></div>
                    <p className="text-3xl font-bold bg-gradient-to-r from-blue-400 via-indigo-400 to-purple-400 bg-clip-text text-transparent mb-1">
                      {stat.value}
                    </p>
                    <p className="text-sm text-blue-200/80 font-medium">{stat.label}</p>
                  </div>
                ))}
              </div>

              {/* Industries Section */}
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <h4 className="text-lg font-semibold bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-transparent">
                    Industry Solutions
                  </h4>
                  <span className="text-sm bg-gradient-to-r from-indigo-400 to-purple-400 bg-clip-text text-transparent font-medium">
                    Market Leaders
                  </span>
                </div>
                <div className="space-y-3">
                  {industries.map((industry, index) => (
                    <div key={index} className="group/card relative border border-white/10 rounded-xl overflow-hidden transition-all duration-300 hover:border-white/20">
                      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 via-indigo-500/5 to-purple-500/0 opacity-0 group-hover/card:opacity-100 transition-opacity duration-500"></div>
                      <button onClick={() => setActiveIndex(activeIndex === index ? null : index)} className="relative w-full flex items-center justify-between p-4 text-left hover:bg-white/5">
                        <span className="font-medium text-white group-hover/card:text-blue-100 transition-colors">
                          {industry.title}
                        </span>
                        <ChevronDown className={`w-5 h-5 text-blue-200/80 transition-transform duration-300 ${activeIndex === index ? 'rotate-180' : ''}`} />
                      </button>
                      {activeIndex === index && (
                        <div className="relative px-4 pb-4">
                          <p className="text-sm text-blue-200/80">{industry.description}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
