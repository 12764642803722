import React from 'react';
import FormField from '../FormField';

interface GrowthGoalsProps {
  formData: {
    targetLeads: string;
    revenueGoal: string;
    startDate: string;
  };
  onChange: (name: string, value: string) => void;
  onNext: () => void;
  onBack: () => void;
}

const GrowthGoals: React.FC<GrowthGoalsProps> = ({ formData, onChange, onNext, onBack }) => {
  const leadRanges = [
    { value: '25-50', label: '25-50 leads per month' },
    { value: '51-100', label: '51-100 leads per month' },
    { value: '101-200', label: '101-200 leads per month' },
    { value: '201-500', label: '201-500 leads per month' },
    { value: '500+', label: '500+ leads per month' }
  ];

  const revenueRanges = [
    { value: '100k-250k', label: '$100k-$250k per month' },
    { value: '251k-500k', label: '$251k-$500k per month' },
    { value: '501k-1m', label: '$501k-$1M per month' },
    { value: '1m+', label: '$1M+ per month' }
  ];

  const startDateRanges = [
    { value: 'immediately', label: 'Immediately' },
    { value: '2-weeks', label: 'Within 2 weeks' },
    { value: '1-month', label: 'Within 1 month' },
    { value: '2-3-months', label: 'Within 2-3 months' },
    { value: '3-months+', label: '3+ months' }
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.targetLeads && formData.revenueGoal && formData.startDate) {
      onNext();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <h2 className="text-2xl font-bold text-white mb-8">Growth Goals</h2>
      
      <FormField
        label="How many leads would you like to get per month?"
        name="targetLeads"
        type="select"
        value={formData.targetLeads}
        onChange={onChange}
        options={leadRanges}
        required
      />
      
      <div className="text-sm text-gray-400 -mt-4 mb-6">
        We'll help you achieve these goals through our proven AI-powered system
      </div>
      
      <FormField
        label="What's your target monthly revenue?"
        name="revenueGoal"
        type="select"
        value={formData.revenueGoal}
        onChange={onChange}
        options={revenueRanges}
        required
      />

      <FormField
        label="When would you like to start?"
        name="startDate"
        type="select"
        value={formData.startDate}
        onChange={onChange}
        options={startDateRanges}
        required
      />

      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="px-6 py-2 bg-white/5 hover:bg-white/10 text-white rounded-lg transition-colors"
        >
          Back
        </button>
        <button
          type="submit"
          className="px-6 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:opacity-90 transition-opacity"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default GrowthGoals;
