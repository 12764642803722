import React from 'react';
import FormField from '../FormField';

interface ContactInfoProps {
  formData: {
    name: string;
    email: string;
    phone: string;
  };
  onChange: (name: string, value: string) => void;
  onNext: () => void;
  onBack: () => void;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ formData, onChange, onNext, onBack }) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.name && formData.email && formData.phone) {
      onNext();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <h2 className="text-2xl font-bold text-white mb-8">Contact Information</h2>
      
      <FormField
        label="What's your name?"
        name="name"
        type="text"
        value={formData.name}
        onChange={onChange}
        placeholder="Enter your full name"
        required
      />
      
      <FormField
        label="What's your email address?"
        name="email"
        type="email"
        value={formData.email}
        onChange={onChange}
        placeholder="email@example.com"
        required
      />

      <FormField
        label="What's your phone number?"
        name="phone"
        type="tel"
        value={formData.phone}
        onChange={onChange}
        placeholder="(555) 555-5555"
        required
      />

      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="px-6 py-2 bg-white/5 hover:bg-white/10 text-white rounded-lg transition-colors"
        >
          Back
        </button>
        <button
          type="submit"
          className="px-6 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:opacity-90 transition-opacity"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default ContactInfo;
