import React from 'react';
import { Search, Target, Crosshair, Star, Calendar, Instagram, BookOpen, MessageCircle, Users, LucideIcon } from 'lucide-react';

interface Feature {
  icon: LucideIcon;
  gradient: string;
  title: string;
  description: string;
}

const features: Feature[] = [
  {
    icon: Search,
    gradient: 'from-indigo-500 via-purple-500 to-pink-500',
    title: 'Custom SEO Website & Booking',
    description: 'High-converting, mobile-optimized website with integrated booking forms and AI-powered SEO optimization.'
  },
  {
    icon: Target,
    gradient: 'from-rose-500 via-pink-500 to-purple-500',
    title: 'AI Lead Generation System',
    description: 'Industry-specific, automated workflows to capture, nurture, and convert leads 24/7 with smart follow-ups.'
  },
  {
    icon: Crosshair,
    gradient: 'from-blue-500 via-cyan-500 to-teal-500',
    title: 'Google Screened & Local SEO',
    description: 'Get your business Google Screened and dominate local search rankings with advanced SEO techniques.'
  },
  {
    icon: Star,
    gradient: 'from-amber-400 via-orange-400 to-rose-400',
    title: 'Automated Review Management',
    description: 'AI-driven review collection system with automated responses to build a 5-star reputation.'
  },
  {
    icon: Calendar,
    gradient: 'from-sky-400 via-blue-500 to-indigo-500',
    title: 'Smart Booking Automation',
    description: 'Customized booking system with automated reminders and follow-ups to maximize appointments.'
  },
  {
    icon: Instagram,
    gradient: 'from-fuchsia-500 via-purple-500 to-pink-500',
    title: 'AI Social Media Strategy',
    description: 'Automated Instagram posting and engagement with industry-specific content frameworks.'
  },
  {
    icon: BookOpen,
    gradient: 'from-violet-500 via-purple-500 to-fuchsia-500',
    title: 'Complete Training & Support',
    description: 'Comprehensive training and ongoing support to ensure your success with the system.'
  },
  {
    icon: MessageCircle,
    gradient: 'from-emerald-500 via-green-500 to-teal-500',
    title: 'AI Chat & SMS Automation',
    description: 'Intelligent chat and SMS automation to engage leads and customers instantly.'
  },
  {
    icon: Users,
    gradient: 'from-pink-500 via-rose-500 to-red-500',
    title: 'Team Collaboration Tools',
    description: 'Built-in tools for team coordination and task management to streamline operations.'
  }
];

const Features: React.FC = () => {
  return (
    <section className="py-24 bg-[#090D1F] relative overflow-hidden" id="features">
      {/* Enhanced Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(#394BFF_1px,transparent_1px)] [background-size:16px_16px] opacity-[0.15]"/>
        <div className="absolute top-0 -left-1/4 w-1/2 h-1/2 bg-[radial-gradient(circle,rgba(57,75,255,0.2),transparent_50%)]"/>
        <div className="absolute bottom-0 -right-1/4 w-1/2 h-1/2 bg-[radial-gradient(circle,rgba(57,75,255,0.2),transparent_50%)]"/>
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500/10 via-purple-500/10 to-pink-500/10 border border-indigo-500/20 backdrop-blur-sm hover:border-indigo-500/30 transition-all duration-300 hover:scale-[1.02] group mb-4">
            <span className="w-5 h-5 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full flex items-center justify-center text-white text-sm relative">
              ⚡
            </span>
            <span className="bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 bg-clip-text text-transparent font-medium">
              Transform Your Business with AI
            </span>
          </div>
          <h2 className="text-4xl font-bold text-white mb-6">
            Our comprehensive suite of AI-powered tools automates your marketing and maximizes your business growth.
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div key={index} className="group relative">
                <div className="relative p-8 rounded-2xl bg-[#0A0B2C]/80 backdrop-blur-xl hover:transform hover:scale-[1.02] transition-all duration-300 border border-white/5 hover:border-white/10">
                  {/* Icon Container with Enhanced Gradient */}
                  <div className="relative w-16 h-16 mb-6">
                    <div className={"absolute inset-0 bg-gradient-to-r ".concat(feature.gradient, " opacity-20 rounded-2xl blur-xl group-hover:opacity-30 transition-opacity duration-300")}/>
                    <div className={"relative h-full w-full rounded-2xl bg-gradient-to-r ".concat(feature.gradient, " p-4 group-hover:shadow-lg group-hover:shadow-").concat(feature.gradient.split(' ')[0], "/20 transition-shadow duration-300")}>
                      <Icon className="w-full h-full text-white/90" strokeWidth={1.5}/>
                    </div>
                  </div>

                  <h3 className="text-xl font-semibold text-white mb-3">
                    {feature.title}
                  </h3>
                  <p className="text-blue-100/80">
                    {feature.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Features;
