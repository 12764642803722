import React from 'react';
import FormField from '../FormField';

interface CurrentPerformanceProps {
  formData: {
    currentLeads: string;
    currentRevenue: string;
    adSpend: string;
  };
  onChange: (name: string, value: string) => void;
  onNext: () => void;
  onBack: () => void;
}

const CurrentPerformance: React.FC<CurrentPerformanceProps> = ({ formData, onChange, onNext, onBack }) => {
  const leadRanges = [
    { value: '0-10', label: '0-10 leads per month' },
    { value: '11-25', label: '11-25 leads per month' },
    { value: '26-50', label: '26-50 leads per month' },
    { value: '51-100', label: '51-100 leads per month' },
    { value: '100+', label: '100+ leads per month' }
  ];

  const revenueRanges = [
    { value: '0-50k', label: '$0-$50k per month' },
    { value: '51k-100k', label: '$51k-$100k per month' },
    { value: '101k-250k', label: '$101k-$250k per month' },
    { value: '251k-500k', label: '$251k-$500k per month' },
    { value: '500k+', label: '$500k+ per month' }
  ];

  const adSpendRanges = [
    { value: '0', label: 'Not currently spending' },
    { value: '1-1000', label: '$1-$1,000 per month' },
    { value: '1001-5000', label: '$1,001-$5,000 per month' },
    { value: '5001-10000', label: '$5,001-$10,000 per month' },
    { value: '10000+', label: '$10,000+ per month' }
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.currentLeads && formData.currentRevenue && formData.adSpend) {
      onNext();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <h2 className="text-2xl font-bold text-white mb-8">Current Performance</h2>
      
      <FormField
        label="How many leads do you currently get per month?"
        name="currentLeads"
        type="select"
        value={formData.currentLeads}
        onChange={onChange}
        options={leadRanges}
        required
      />
      
      <FormField
        label="What's your current monthly revenue?"
        name="currentRevenue"
        type="select"
        value={formData.currentRevenue}
        onChange={onChange}
        options={revenueRanges}
        required
      />

      <FormField
        label="What's your current monthly ad spend?"
        name="adSpend"
        type="select"
        value={formData.adSpend}
        onChange={onChange}
        options={adSpendRanges}
        required
      />

      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="px-6 py-2 bg-white/5 hover:bg-white/10 text-white rounded-lg transition-colors"
        >
          Back
        </button>
        <button
          type="submit"
          className="px-6 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:opacity-90 transition-opacity"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default CurrentPerformance;
