import React from 'react';
import { Rocket, Clock, Target, Mail, Phone, MapPin, Instagram, ArrowRight } from 'lucide-react';

interface ValueProp {
  title: string;
  icon: React.ReactNode;
  points: string[];
}

const valueProps: ValueProp[] = [
  {
    title: "Own Your Marketing System",
    icon: <Rocket className="w-6 h-6" />,
    points: [
      "No more paying expensive agency retainers",
      "Full control over your marketing strategy",
      "Scale operations without increasing overhead"
    ]
  },
  {
    title: "24/7 Lead Generation",
    icon: <Clock className="w-6 h-6" />,
    points: [
      "Never miss another after-hours lead",
      "Automated follow-up sequences",
      "Consistent lead flow while you sleep"
    ]
  },
  {
    title: "Local Search Domination",
    icon: <MapPin className="w-6 h-6" />,
    points: [
      "Rank #1 in Google Maps results",
      "Dominate local service areas",
      "Outrank larger competitors"
    ]
  }
];

const Footer: React.FC = () => {
  return (
    <footer className="relative bg-gradient-to-b from-[#0A0B2C] to-[#070818] z-20">
      {/* Value Propositions */}
      <div className="container mx-auto px-4 py-20">
        <div className="grid md:grid-cols-3 gap-12">
          {valueProps.map((prop, index) => (
            <div key={index} className="space-y-6 p-6 rounded-2xl bg-gradient-to-br from-blue-500/5 to-purple-500/5 hover:from-blue-500/10 hover:to-purple-500/10 transition-all duration-300 border border-blue-500/10 hover:border-blue-500/20">
              <div className="flex items-center gap-3">
                <div className="p-3 rounded-xl bg-gradient-to-br from-blue-500/10 to-purple-500/10">
                  {prop.icon}
                </div>
                <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                  {prop.title}
                </h3>
              </div>
              <ul className="space-y-4">
                {prop.points.map((point, idx) => (
                  <li key={idx} className="flex items-center gap-3 text-blue-100 group">
                    <ArrowRight className="w-4 h-4 text-blue-400 group-hover:translate-x-1 transition-transform duration-300" />
                    <span className="group-hover:text-white transition-colors duration-300">{point}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* SEO-Rich Content */}
      <div className="border-t border-blue-500/10 py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center space-y-8">
            <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
              Transform Your Business with Market Bots
            </h2>
            <p className="text-blue-100 text-lg leading-relaxed">
              Market Bots helps home service businesses and real estate professionals automate their marketing, 
              generate consistent leads, and build a powerful online presence. Our AI-powered system works 24/7 
              to capture leads, schedule appointments, and grow your business while you focus on what matters most.
            </p>
            <p className="text-blue-100 text-lg leading-relaxed">
            Serving clients in junk removal, HVAC, plumbing, landscaping, real estate, property management, and local services across the United States. 
Get started today and join hundreds of successful businesses using Market Bots to dominate their local market.
            </p>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-blue-500/10 py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center justify-between gap-6">
            <div className="flex items-center gap-3">
              <img src="https://storage.googleapis.com/msgsndr/q2wpqhaMykaslwBqknee/media/6733d29782e636857956938e.png" alt="Market Bots" className="h-10" />
            </div>

            <div className="flex items-center gap-6">
              <a href="https://instagram.com/trymarketbots" target="_blank" rel="noopener noreferrer" className="p-2 rounded-lg hover:bg-blue-500/10 text-blue-100 hover:text-white transition-all duration-300">
                <Instagram className="w-5 h-5" />
              </a>
              <a href="mailto:hello@marketbots.com" className="p-2 rounded-lg hover:bg-blue-500/10 text-blue-100 hover:text-white transition-all duration-300">
                <Mail className="w-5 h-5" />
              </a>
            </div>
            <div className="flex items-center gap-4 text-sm text-blue-100">
              <a href="/disclosures" className="hover:text-white transition-colors duration-300">
                Disclosures
              </a>
              <span>|</span>
              <p> {new Date().getFullYear()} Market Bots. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
