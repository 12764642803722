import React from 'react';
import { Helmet } from 'react-helmet';

interface SchemaMarkupProps {
  section?: 'home' | 'features' | 'pricing' | 'testimonials';
}

const SchemaMarkup: React.FC<SchemaMarkupProps> = ({ section = 'home' }) => {
  const baseSchema = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Market Bots",
    "applicationCategory": "BusinessApplication",
    "operatingSystem": "Web-based",
    "offers": {
      "@type": "Offer",
      "price": "497",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "150"
    }
  };

  const sectionSchemas = {
    home: {
      ...baseSchema,
      "description": "AI-powered lead generation and business automation platform for modern businesses.",
      "featureList": "Lead Generation, AI Automation, CRM Integration, Review Management"
    },
    features: {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "AI Lead Generation",
          "description": "Automated lead generation using artificial intelligence"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Business Automation",
          "description": "Streamline operations with AI-powered automation"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "CRM Integration",
          "description": "Seamless integration with popular CRM platforms"
        }
      ]
    },
    pricing: {
      "@context": "https://schema.org",
      "@type": "PriceSpecification",
      "price": "497",
      "priceCurrency": "USD",
      "description": "Starting price for Market Bots AI marketing platform"
    },
    testimonials: {
      "@context": "https://schema.org",
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5",
        "bestRating": "5"
      },
      "author": {
        "@type": "Person",
        "name": "John Smith"
      },
      "reviewBody": "Market Bots transformed our lead generation process. We've seen a 300% increase in qualified leads."
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(sectionSchemas[section])}
      </script>
    </Helmet>
  );
};

export default SchemaMarkup;
