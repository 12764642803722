import React from 'react';
import { Briefcase, Zap, Target, ArrowRight, CheckCircle } from 'lucide-react';

interface TimelineProps {
  onGetStarted?: () => void;
}

interface Month {
  icon: React.ComponentType<any>;
  gradient: string;
  title: string;
  subtitle: string;
  description: string;
  milestones: string[];
}

const months: Month[] = [
  {
    icon: Briefcase,
    gradient: 'from-indigo-500 via-purple-500 to-pink-500',
    title: 'Month 1: Foundation',
    subtitle: 'Establish Your Digital Presence',
    description: 'Launch your custom SEO website and booking system, verified by Google.',
    milestones: [
      'Custom website with SEO optimization',
      'Google Business Profile setup',
      'Automated booking system',
      'Local SEO implementation'
    ]
  },
  {
    icon: Zap,
    gradient: 'from-rose-500 via-pink-500 to-purple-500',
    title: 'Month 2: Automation',
    subtitle: 'Scale Your Lead Generation',
    description: 'Set up automated workflows, nurture sequences, and social media automation.',
    milestones: [
      'Lead generation automation',
      'Email nurture sequences',
      'Social media scheduling',
      'Analytics dashboard setup'
    ]
  },
  {
    icon: Target,
    gradient: 'from-blue-500 via-cyan-500 to-teal-500',
    title: 'Month 3: Optimization',
    subtitle: 'Maximize ROI & Growth',
    description: 'Advanced optimization and performance tracking ensure sustainable growth.',
    milestones: [
      'Conversion rate optimization',
      'A/B testing implementation',
      'Performance analytics',
      'Growth strategy refinement'
    ]
  }
];

export const Timeline: React.FC<TimelineProps> = ({ onGetStarted }) => {
  return (
    <section className="py-20 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <div className="inline-block">
            <span className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-blue-500/10 via-indigo-500/10 to-purple-500/10 border border-blue-500/20 backdrop-blur-sm">
              <span className="w-2 h-2 rounded-full bg-gradient-to-r from-blue-500 to-indigo-500"></span>
              <span className="bg-gradient-to-r from-blue-400 via-indigo-400 to-purple-400 bg-clip-text text-transparent font-medium">
                90-Day Transformation Program
              </span>
            </span>
          </div>
          <h2 className="text-4xl font-bold mt-4 mb-6">
            Your Journey to{' '}
            <span className="bg-gradient-to-r from-secondary via-blue-500 to-secondary text-transparent bg-clip-text">
              Marketing Excellence
            </span>
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Our proven 90-day program transforms your business into a lead generation machine.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {months.map((month, index) => {
            const Icon = month.icon;

            return (
              <div key={index} className="relative p-6 rounded-2xl bg-gradient-to-b from-white/[0.12] to-transparent border border-white/[0.08] group hover:border-secondary/50 transition-colors">
                <div className="absolute inset-0 bg-gradient-to-b from-white/[0.08] to-transparent opacity-0 group-hover:opacity-100 transition-opacity rounded-2xl"/>
                <div className="relative">
                  <div className={`inline-flex p-3 rounded-xl bg-gradient-to-r ${month.gradient} mb-6`}>
                    <Icon className="w-6 h-6 text-white"/>
                  </div>
                  <h3 className="text-2xl font-bold mb-2">{month.title}</h3>
                  <div className="text-secondary font-semibold mb-4">{month.subtitle}</div>
                  <p className="text-gray-300 mb-6">{month.description}</p>
                  <ul className="space-y-3">
                    {month.milestones.map((milestone, idx) => (
                      <li key={idx} className="flex items-center gap-3">
                        <ArrowRight className={`w-5 h-5 flex-shrink-0 bg-gradient-to-r ${month.gradient} rounded-full p-1`}/>
                        <span className="text-gray-300">{milestone}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>

        <div className="text-center mt-12">
          <button onClick={onGetStarted} className="group relative inline-flex items-center gap-2 px-8 py-4 bg-gradient-to-r from-secondary via-blue-500 to-secondary bg-[length:200%_100%] text-white rounded-lg font-medium transition-all duration-500 hover:bg-[length:100%_100%] hover:scale-105 hover:shadow-[0_0_40px_8px_rgba(57,75,255,0.3)]">
            <span>Start Your Transformation</span>
            <CheckCircle className="w-5 h-5 group-hover:translate-x-1 transition-transform"/>
          </button>
        </div>
      </div>
    </section>
  );
};
