import React, { useState } from 'react';
import { X } from 'lucide-react';
import { 
  BusinessInfo,
  CurrentPerformance,
  GrowthGoals,
  ContactInfo,
  AdditionalDetails,
  ThankYou
} from './steps';
import { ProgressBar } from './components';

interface FormData {
  businessName: string;
  industry: string;
  website: string;
  currentLeads: string;
  currentRevenue: string;
  adSpend: string;
  targetLeads: string;
  revenueGoal: string;
  startDate: string;
  name: string;
  email: string;
  phone: string;
  challenge: string;
  [key: string]: string;
}

interface ApplicationFormProps {
  onClose: () => void;
}

const WEBHOOK_URL = 'https://services.leadconnectorhq.com/hooks/q2wpqhaMykaslwBqknee/webhook-trigger/888c7c87-2d4c-4d85-8fdd-cbd5b00a4108';

const steps = [
  'Business Information',
  'Current Performance',
  'Growth Goals',
  'Contact Information',
  'Additional Details'
];

const ApplicationForm: React.FC<ApplicationFormProps> = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<FormData>({
    businessName: '',
    industry: '',
    website: '',
    currentLeads: '',
    currentRevenue: '',
    adSpend: '',
    targetLeads: '',
    revenueGoal: '',
    startDate: '',
    name: '',
    email: '',
    phone: '',
    challenge: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (name: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          businessInfo: {
            businessName: formData.businessName,
            industry: formData.industry,
            website: formData.website,
          },
          currentPerformance: {
            currentLeads: formData.currentLeads,
            currentRevenue: formData.currentRevenue,
            adSpend: formData.adSpend,
          },
          growthGoals: {
            targetLeads: formData.targetLeads,
            revenueGoal: formData.revenueGoal,
            startDate: formData.startDate,
          },
          contactInfo: {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
          },
          additionalDetails: {
            challenge: formData.challenge,
          }
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      setIsSubmitted(true);
      setCurrentStep(steps.length);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (show error message to user)
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <BusinessInfo
            formData={formData}
            onChange={handleInputChange}
            onNext={handleNext}
          />
        );
      case 1:
        return (
          <CurrentPerformance
            formData={formData}
            onChange={handleInputChange}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 2:
        return (
          <GrowthGoals
            formData={formData}
            onChange={handleInputChange}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 3:
        return (
          <ContactInfo
            formData={formData}
            onChange={handleInputChange}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 4:
        return (
          <AdditionalDetails
            formData={formData}
            onChange={handleInputChange}
            onSubmit={handleSubmit}
            onBack={handleBack}
            isSubmitting={isSubmitting}
          />
        );
      case 5:
        return <ThankYou onClose={onClose} />;
      default:
        return null;
    }
  };

  return (
    <section className="min-h-screen bg-[#000437] py-24">
      <div className="container max-w-3xl mx-auto px-4">
        <div className="relative">
          <button onClick={onClose} className="absolute -top-4 -right-4 w-8 h-8 bg-white/10 hover:bg-white/20 rounded-full flex items-center justify-center text-white transition-colors">
            <X className="w-4 h-4"/>
          </button>

          <div className="text-center mb-12">
            <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-secondary/10 text-secondary text-sm font-medium backdrop-blur-sm mb-4">
              <span className="w-4 h-4">🚀</span>
              90-Day Transformation Program
            </div>
            <h1 className="text-4xl font-bold text-white mb-4">
              Build Your Own{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-secondary to-blue-400">
                In-House Marketing Agency
              </span>
            </h1>
            <p className="text-xl text-blue-100">
              Let's customize your AI marketing system
            </p>
          </div>

          <div className="bg-[#0A0B2C]/80 backdrop-blur-xl rounded-2xl p-8 border border-white/5">
            <ProgressBar currentStep={currentStep} totalSteps={steps.length}/>
            
            <div className="mt-8">
              <h2 className="text-2xl font-semibold text-white mb-2">
                {steps[currentStep]}
              </h2>
              <p className="text-blue-100 mb-8">
                Step {currentStep + 1} of {steps.length}
              </p>

              {renderStep()}

              <div className="flex justify-between mt-8 pt-8 border-t border-white/10">
                {currentStep > 0 && (<button onClick={handleBack} className="flex items-center gap-2 px-6 py-3 bg-white/5 hover:bg-white/10 text-white rounded-lg transition-colors" disabled={isSubmitting}>
                    <X className="w-4 h-4"/>
                    Previous
                  </button>)}
                
                <button onClick={currentStep === steps.length - 1 ? handleSubmit : handleNext} className="flex items-center gap-2 px-6 py-3 bg-secondary hover:bg-secondary/90 text-white rounded-lg ml-auto transition-colors disabled:opacity-50 disabled:cursor-not-allowed" disabled={isSubmitting}>
                  {currentStep === steps.length - 1 ? (isSubmitting ? 'Submitting...' : 'Submit') : ('Next')}
                  {currentStep === steps.length - 1 ? (isSubmitting ? null : <X className="w-4 h-4"/>) : (<X className="w-4 h-4"/>)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplicationForm;
